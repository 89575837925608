<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Title" label-for="title">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="form.title"
                  :state="errors.length > 0 ? false : null"
                  name="title"
                  placeholder="Enter Your Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Description" label-for="description">
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                  placeholder="Enter Your Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Featured Text Title"
              label-for="featured_text_title"
            >
              <validation-provider
                #default="{ errors }"
                name="featured_text_title"
                rules="required"
              >
                <b-form-input
                  id="featured_text_title"
                  v-model="form.featured_text_title"
                  :state="errors.length > 0 ? false : null"
                  name="featured_text_title"
                  placeholder="Enter Your Featured Text Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Featured Text Sub  Title"
              label-for="featured_text_sub_title"
            >
              <validation-provider
                #default="{ errors }"
                name="featured_text_sub_title"
                rules="required"
              >
                <b-form-input
                  id="featured_text_sub_title"
                  v-model="form.featured_text_sub_title"
                  :state="errors.length > 0 ? false : null"
                  name="featured_text_sub_title"
                  placeholder="Enter Your Featured Text Sub  Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <div class="d-block" style="height: 410px">
              <b-form-group
                label="Featured Text Description"
                label-for="featured_text_description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="featured_text_description"
                  rules="required"
                >
                  <!-- :options="snowOption" -->
                  <quill-editor
                    id="featured_text_description"
                    style="height: 320px"
                    class="mb-4"
                    v-model="form.featured_text_description"
                    name="featured_text_description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Your Featured Text Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-12">
            <div class="border rounded p-2 mb-2">
              <b-media
                no-body
                vertical-align="center"
                class="flex-column align-middle flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    :src="image"
                    height="174"
                    width="310"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-form-group label="Featured Image" label-for="image">
                    <b-form-file
                      id="image"
                      accept=".jpg, .png, .gif"
                      @change="loadImage($event)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                  </b-form-group>
                </b-media-body>
              </b-media>
            </div>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Cit Initiative Title"
              label-for="cit_initiatives_title"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_initiatives_title"
                rules="required"
              >
                <b-form-input
                  id="cit_initiatives_title"
                  v-model="form.cit_initiatives_title"
                  :state="errors.length > 0 ? false : null"
                  name="cit_initiatives_title"
                  placeholder="Enter Your Cit Initiative Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Cit Achievement video Section Title"
              label-for="cit_achievement_title"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_achievement_title"
                rules="required"
              >
                <b-form-input
                  id="cit_achievement_title"
                  v-model="form.cit_achievement_title"
                  :state="errors.length > 0 ? false : null"
                  name="cit_achievement_title"
                  placeholder="Enter Your Cit Achievement video Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Cit Milestone Title"
              label-for="cit_milestone_title"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_milestone_title"
                rules="required"
              >
                <b-form-input
                  id="cit_milestone_title"
                  v-model="form.cit_milestone_title"
                  :state="errors.length > 0 ? false : null"
                  name="cit_milestone_title"
                  placeholder="Enter Your Cit Milestone Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Cit Milestone Description"
              label-for="cit_milestone_description"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_milestone_description"
                rules="required"
              >
                <b-form-textarea
                  id="cit_milestone_description"
                  v-model="form.cit_milestone_description"
                  :state="errors.length > 0 ? false : null"
                  name="cit_milestone_description"
                  placeholder="Enter Your Cit Milestone Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Meta Title" label-for="meta_title">
              <validation-provider
                #default="{ errors }"
                name="meta_title"
                rules="required"
              >
                <b-form-input
                  id="meta_title"
                  v-model="form.meta_title"
                  :state="errors.length > 0 ? false : null"
                  name="meta_title"
                  placeholder="Enter Your Meta Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Meta Description" label-for="meta_description">
              <validation-provider
                #default="{ errors }"
                name="meta_description"
                rules="required"
              >
                <b-form-textarea
                  id="meta_description"
                  v-model="form.meta_description"
                  :state="errors.length > 0 ? false : null"
                  name="meta_description"
                  placeholder="Enter Your Meta Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <!-- submit buttons -->
        <b-button type="submit" variant="primary" @click="validationForm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
export default {
  props: ["marketplaces"],
  data() {
    return {
      form: {
        title: "",
        description: "",
        featured_text_title: "",
        featured_text_sub_title: "",
        featured_text_description: "",
        cit_milestone_title: "",
        cit_milestone_description: "",
        cit_initiatives_title: "",
        cit_achievement_title: "",
        meta_title: "",
        meta_description: "",
        image: "",
      },
      image: null,
      required,
      email,
    };
  },
  mounted() {
    this.getPage();
  },
  methods: {
    async getPage() {
      const achievement = await this.callApi(
        "get",
        "/app/our/achievement/page/show"
      );
      if (achievement.status == 200) {
        this.form.title = achievement.data.achievement.title;
        this.form.description = achievement.data.achievement.description;
        this.form.featured_text_title =
          achievement.data.achievement.featured_text_title;
        this.form.featured_text_sub_title =
          achievement.data.achievement.featured_text_sub_title;
        this.form.featured_text_description =
          achievement.data.achievement.featured_text_description;
        this.form.cit_milestone_title =
          achievement.data.achievement.cit_milestone_title;
        this.form.cit_milestone_description =
          achievement.data.achievement.cit_milestone_description;
        this.form.cit_initiatives_title =
          achievement.data.achievement.cit_initiatives_title;
        this.form.cit_achievement_title =
          achievement.data.achievement.cit_achievement_title;
        this.form.meta_title = achievement.data.achievement.meta_title;
        this.form.meta_description =
          achievement.data.achievement.meta_description;

        this.image =
          this.$store.state.base_url +
          (achievement.data.achievement.image
            ? achievement.data.achievement.image
            : "images/our_achievement_page/default.jpg");
      }
    },

    loadImage(e) {
      this.form.image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Update() {
      const fd = new FormData();
      if (this.form.image) {
        fd.append("image", this.form.image, this.form.image.name);
      }
      fd.append("title", this.form.title);
      fd.append("description", this.form.description);
      fd.append("featured_text_title", this.form.featured_text_title);
      fd.append("featured_text_sub_title", this.form.featured_text_sub_title);
      fd.append(
        "featured_text_description",
        this.form.featured_text_description
      );
      fd.append("cit_milestone_title", this.form.cit_milestone_title);
      fd.append(
        "cit_milestone_description",
        this.form.cit_milestone_description
      );
      fd.append("cit_initiatives_title", this.form.cit_initiatives_title);
      fd.append("cit_achievement_title", this.form.cit_achievement_title);
      fd.append("meta_title", this.form.meta_title);
      fd.append("meta_description", this.form.meta_description);
      axios
        .post("/app/our/achievement/page/update", fd)
        .then((res) => {
          this.getPage();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.image)
              this.e(e.response.data.errors.image[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    quillEditor,
    vSelect,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
