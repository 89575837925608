var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"title","placeholder":"Enter Your Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description","placeholder":"Enter Your Description","rows":"4"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Featured Text Title","label-for":"featured_text_title"}},[_c('validation-provider',{attrs:{"name":"featured_text_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"featured_text_title","state":errors.length > 0 ? false : null,"name":"featured_text_title","placeholder":"Enter Your Featured Text Title"},model:{value:(_vm.form.featured_text_title),callback:function ($$v) {_vm.$set(_vm.form, "featured_text_title", $$v)},expression:"form.featured_text_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Featured Text Sub  Title","label-for":"featured_text_sub_title"}},[_c('validation-provider',{attrs:{"name":"featured_text_sub_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"featured_text_sub_title","state":errors.length > 0 ? false : null,"name":"featured_text_sub_title","placeholder":"Enter Your Featured Text Sub  Title"},model:{value:(_vm.form.featured_text_sub_title),callback:function ($$v) {_vm.$set(_vm.form, "featured_text_sub_title", $$v)},expression:"form.featured_text_sub_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"d-block",staticStyle:{"height":"410px"}},[_c('b-form-group',{attrs:{"label":"Featured Text Description","label-for":"featured_text_description"}},[_c('validation-provider',{attrs:{"name":"featured_text_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"mb-4",staticStyle:{"height":"320px"},attrs:{"id":"featured_text_description","name":"featured_text_description","state":errors.length > 0 ? false : null,"placeholder":"Enter Your Featured Text Title"},model:{value:(_vm.form.featured_text_description),callback:function ($$v) {_vm.$set(_vm.form, "featured_text_description", $$v)},expression:"form.featured_text_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"border rounded p-2 mb-2"},[_c('b-media',{staticClass:"flex-column align-middle flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.image,"height":"174","width":"310"}})],1),_c('b-media-body',[_c('b-form-group',{attrs:{"label":"Featured Image","label-for":"image"}},[_c('b-form-file',{attrs:{"id":"image","accept":".jpg, .png, .gif","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.loadImage($event)}}})],1)],1)],1)],1)]),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Cit Initiative Title","label-for":"cit_initiatives_title"}},[_c('validation-provider',{attrs:{"name":"cit_initiatives_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cit_initiatives_title","state":errors.length > 0 ? false : null,"name":"cit_initiatives_title","placeholder":"Enter Your Cit Initiative Title"},model:{value:(_vm.form.cit_initiatives_title),callback:function ($$v) {_vm.$set(_vm.form, "cit_initiatives_title", $$v)},expression:"form.cit_initiatives_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Cit Achievement video Section Title","label-for":"cit_achievement_title"}},[_c('validation-provider',{attrs:{"name":"cit_achievement_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cit_achievement_title","state":errors.length > 0 ? false : null,"name":"cit_achievement_title","placeholder":"Enter Your Cit Achievement video Section Title"},model:{value:(_vm.form.cit_achievement_title),callback:function ($$v) {_vm.$set(_vm.form, "cit_achievement_title", $$v)},expression:"form.cit_achievement_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Cit Milestone Title","label-for":"cit_milestone_title"}},[_c('validation-provider',{attrs:{"name":"cit_milestone_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cit_milestone_title","state":errors.length > 0 ? false : null,"name":"cit_milestone_title","placeholder":"Enter Your Cit Milestone Title"},model:{value:(_vm.form.cit_milestone_title),callback:function ($$v) {_vm.$set(_vm.form, "cit_milestone_title", $$v)},expression:"form.cit_milestone_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Cit Milestone Description","label-for":"cit_milestone_description"}},[_c('validation-provider',{attrs:{"name":"cit_milestone_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"cit_milestone_description","state":errors.length > 0 ? false : null,"name":"cit_milestone_description","placeholder":"Enter Your Cit Milestone Description","rows":"4"},model:{value:(_vm.form.cit_milestone_description),callback:function ($$v) {_vm.$set(_vm.form, "cit_milestone_description", $$v)},expression:"form.cit_milestone_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Meta Title","label-for":"meta_title"}},[_c('validation-provider',{attrs:{"name":"meta_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"meta_title","state":errors.length > 0 ? false : null,"name":"meta_title","placeholder":"Enter Your Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Meta Description","label-for":"meta_description"}},[_c('validation-provider',{attrs:{"name":"meta_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"meta_description","state":errors.length > 0 ? false : null,"name":"meta_description","placeholder":"Enter Your Meta Description","rows":"4"},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Update ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }